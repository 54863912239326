/* src/components/styles.css */
.app {
  padding: 50px;
}

.checkbox-label {
  margin-top: 5px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.checkbox-label input {
  width: min-content;
  padding: 0;
}

.form-group {
  margin-bottom: 15px;
}

label {
  display: block;
  margin-bottom: 5px;
}

input {
  width: 100%;
  padding: 8px;
  box-sizing: border-box;
}

button {
  padding: 10px 20px;
  background-color: #007bff;
  color: white;
  border: none;
  cursor: pointer;
  width: 100%;
}

button:hover {
  background-color: #0056b3;
}
